//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  > a {
    color: $gray-800;
  }
  + .breadcrumb-item {
    &::before {
      padding: 0 5px;
      color: rgba($dark, 0.5);
      content: '\f105' !important;
      font-family: Helvetica;
      font-weight: 700;
    }
  }
}
